<script setup lang="ts">
import { Btn } from 'webcc-ui-components'
import { OtherFormType } from '~/schema'

const emit = defineEmits<{ (e: 'click'): void }>()

const { t } = useI18n()
const { $ROUTE } = useNuxtApp()

const errorDisclaimerText = computed(() =>
  serializeSentenceWithLink(
    t('components.common.dataErrorDisclaimer.description'),
  ),
)

function contactUs() {
  navigateTo(`${$ROUTE.CONTACT_US}?type=${OtherFormType.Other}`)
  emit('click')
}
</script>

<template>
  <div class="bg-bgr-100 p-6 pb-8 text-center">
    <p class="mb-4 text-xl font-medium text-txt-500">
      <slot name="title">
        {{ $t('components.common.dataErrorDisclaimer.title') }}
      </slot>
    </p>
    <p class="text-base text-txt-100">
      <slot>
        {{ errorDisclaimerText.labelBefore }}
        <Btn
          class="font-normal"
          variant="link"
          :label="errorDisclaimerText.linkText"
          @click="contactUs"
        />
        {{ errorDisclaimerText.labelAfter }}
      </slot>
    </p>
  </div>
</template>
